import React, { ComponentType, PropsWithChildren } from 'react';

import getDisplayName from 'common/tools/react/getDisplayName';

import { BRAND_HAS_USERSPACE } from 'website/configuration/constants';

export default <T,>(ComposedComponent: ComponentType<PropsWithChildren<T>>) => {
  const OnlyIfHasSocial = (props: PropsWithChildren<T>) =>
    BRAND_HAS_USERSPACE ? <ComposedComponent {...props} /> : null;

  OnlyIfHasSocial.displayName = `OnlyIfHasSocial(${getDisplayName(
    ComposedComponent
  )})`;

  return OnlyIfHasSocial;
};
