/* global AC */
export const LANG = AC.config.lang;
export const SITE_KEY = AC.config.siteKey;

export const API_SECURE_HOST = AC.config.hosts.secureApi;

export const ANONYMOUS_AVATAR =
  'http://fr.web.img5.stg.acsta.net/c_75_80/commons/emptymedia/avatar_default.jpg';

export const DISQUS_SHORTNAME = AC.config.disqus.shortname;
export const DISQUS_API_KEY = AC.config.disqus.apiKey;
export const DISQUS_SSO_BUTTON = AC.config.disqus.ssoButton;

export const IMAGE_SPACER_3_4 = AC.config.images.spacer34;

export const MEDIAMETRIE_LIB_URL = AC.config.player.mediametrie.libUrl;
export const MEDIAMETRIE_STREAMING_SERIAL = AC.config.player.mediametrie.serial;

export const GTM_PLAYER_ACTIVE = AC.config.player.gtm.active;

export const COMSCORE_CLIENT_ID = AC.config.player.comscore.clientId;

export const GEMIUS_LIB_URL = AC.config.player.gemius.libUrl;
export const GEMIUS_PREFIX = AC.config.player.gemius.prefix;
export const GEMIUS_IDENTIFIER = AC.config.player.gemius.identifier;

export const AD_DFP_CMS_ID = AC.config.player.dfp.adCmsId;

export const BRAND_IS_AC_LIGHT = AC.config.flags.brandIsAcLight;
export const BRAND_HAS_USERSPACE = AC.config.flags.brandHasUserspace;

export const BRAND_HAS_THEATERS_GROUP = AC.config.flags.brandHasTheatersGroup;

export const BRAND_HAS_SAME_LOCAL_AND_DUBBED_SHOWTIMES =
  AC.config.flags.brandHasSameLocalAndDubbedShowtimes;

export const WEBEDIA_WEBSITES = AC.config.webedia_websites;

export const SEASON_IN_DEVELOPMENT = 122002;
export const SEASON_IN_PRODUCTION = 122004;

export const CSS_SECONDARY_URL = AC.config.assetsUrls.css.secondary;
