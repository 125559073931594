import get from 'common/tools/objects/get';

export const addFavoriteTheaterMapper = response => {
  const theater = get(response, ['data', 'addUserTheater']);
  return {
    targetKey: `theater_${theater.internalId.toLowerCase()}`,
    id: theater.internalId.toLowerCase(),
    label: theater.name
  };
};

export const retrieveUserTheatersMapper = response => {
  const edges = get(response, [
    'data',
    'me',
    'user',
    'social',
    'theaters',
    'edges'
  ]);
  const mapped = edges.reduce(
    (acc, edge, index) => {
      const theaterNode = edge.node;

      acc.actions[`usertheater_${index}`] = {
        targetKey: `theater_${theaterNode.internalId.toLowerCase()}`
      };

      acc.targets[`theater_${theaterNode.internalId.toLowerCase()}`] = {
        id: theaterNode.internalId.toLowerCase(),
        label: theaterNode.name
      };
      return acc;
    },
    { actions: {}, targets: {} }
  );
  mapped.resultCount = get(response, [
    'data',
    'me',
    'user',
    'social',
    'theaters',
    'totalCount'
  ]);
  return mapped;
};
