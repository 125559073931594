import { BRAND_HAS_GROUP_CONSENTS } from 'common/configuration/constants';
import { on } from 'common/tools/dom/events';

let gaConsent: any = null;

const google = 'google';
export const googleana = 'c:googleana-XMqagawa';

export function getConsentForId(id: string) {
  let consents = null;

  const didomi = window.Didomi || window.top?.Didomi;

  if (!didomi) {
    return consents;
  }

  if (didomi.isConsentRequired()) {
    consents = didomi.getUserConsentStatusForVendor(id);
  }

  return consents;
}

export async function getVendorsConsent(
  arrayConsentsIds: string[],
  timeout = true
) {
  return new Promise(resolve => {
    if (!BRAND_HAS_GROUP_CONSENTS) {
      resolve(true);
      return;
    }

    let didomiOnReady = null;

    try {
      didomiOnReady = window.didomiOnReady || window.top?.didomiOnReady;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting didomiOnReady', e);
      resolve(false);
      return;
    }

    if (!didomiOnReady || !arrayConsentsIds.length) {
      resolve(false);
      return;
    }

    if (gaConsent) {
      resolve(gaConsent);
      return;
    }

    let vendorTimeout: any = null;
    if (timeout) {
      vendorTimeout = setTimeout(() => {
        resolve(false);
      }, 3000);
    }

    didomiOnReady.push(() => {
      const consentArray = arrayConsentsIds.map(item => getConsentForId(item));

      if (vendorTimeout) {
        clearTimeout(vendorTimeout);
      }

      gaConsent = consentArray.reduce((acc, currentValue) => {
        if (!currentValue) {
          return false;
        }

        // by definition this will always be true :
        return acc && currentValue;
      });

      resolve(gaConsent);
    });
  });
}

export async function getConsentForAnalitycs(timeout?: boolean) {
  return await getVendorsConsent([googleana, google], timeout);
}

export async function getConsentForGoogle() {
  // use for one tag implementation
  return await getVendorsConsent([google]);
}

export function triggerDidomiPopup(domElementSelector: string, type: string) {
  const elems = document.querySelectorAll(domElementSelector);

  if (!elems) {
    return false;
  }

  elems.forEach(element => {
    on(element, 'click', (e: Event) => {
      e.preventDefault();

      if (!window.Didomi) {
        return false;
      }

      switch (type) {
        case 'notice':
          window.Didomi.notice.show();
          break;
        case 'preferences':
          window.Didomi.preferences.show();
          break;
        default:
          return false;
      }
    });
  });
}
