import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';

type Props = {
  children: (handleClick: (newValue: any) => void) => ReactNode;
  className?: string;
  onChange?: (newValue: any) => void;
  value?: any;
  valueRenderer?: (newValue: any) => ReactNode;
};

const Dropdown = ({
  children,
  className,
  onChange,
  value,
  valueRenderer
}: Props) => {
  const [opened, setOpened] = useState(false);

  const containerClass = classNames('dropdown-custom', className, {
    'is-open': opened
  });

  return (
    <div
      className={containerClass}
      onClick={() => setOpened(isOpened => !isOpened)}
      onMouseLeave={() => setOpened(false)}
    >
      <span className="title">
        {valueRenderer ? valueRenderer(value) : value}
      </span>
      <i className="btn" />
      <ul className="list">
        {children((newValue: any) => onChange?.(newValue))}
      </ul>
    </div>
  );
};

export default Dropdown;
