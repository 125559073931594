import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import DropdownLinks from 'common/components/generic/DropdownLinks';
import { path } from 'common/tools/network/routing';
import trans from 'common/tools/translations/trans';

import { getUserTheaters } from 'website/actions/TheaterActions';
import onlyIfHasSocial from 'website/components/HoC/onlyIfHasSocial';
import AuthenticatedLink from 'website/components/user/AuthenticatedLink';
import { REQUEST_LOADING } from 'website/constants/RequestsStatuses';
import { Dispatch, State } from 'website/reducers';
import { UserState } from 'website/reducers/user';

type FavoriteTheatersSelectorProps = {
  dispatch: Dispatch;
  title: string;
  user: UserState;
};

const useFetchUserTheaters = (dispatch: Dispatch, loggedIn?: boolean) => {
  useEffect(() => {
    if (loggedIn) {
      dispatch(getUserTheaters());
    }
  }, [dispatch, loggedIn]);
};

const FavoriteTheatersSelector = ({
  title,
  user,
  dispatch
}: FavoriteTheatersSelectorProps) => {
  useFetchUserTheaters(dispatch, user.loggedIn);

  const size = useMemo(
    () => Object.keys(user.theaters ?? {}).length,
    [user.theaters]
  );

  const formatTheaters = useCallback(() => {
    const formattedTheaters = [];

    Object.entries(user.theaters ?? {}).forEach(([key, value]) => {
      formattedTheaters.push({
        text: value.data?.label,
        href: path('theater_showtimes', { idTheater: key })
      });
    });

    if ((user.theatersCount ?? 0) > size) {
      formattedTheaters.push({
        text: trans('homepage_movie.all_favorite_theaters_title'),
        href: path('userspace_my_theaters')
      });
    }

    return formattedTheaters;
  }, [size, user.theaters, user.theatersCount]);

  const renderContent = () => {
    if (!user.loggedIn || user.theaters === null) {
      return (
        <AuthenticatedLink className="search-theater-link blue-link">
          {trans('homepage_movie.theaters-logged-out')}
        </AuthenticatedLink>
      );
    }

    if (user.favTheatersStatus === REQUEST_LOADING) {
      return (
        <AuthenticatedLink className="search-theater-link blue-link">
          {trans('homepage_movie.theaters-logged-out')}
        </AuthenticatedLink>
      );
    }

    if (size === 0) {
      return (
        <a
          className="search-theater-link blue-link"
          href={path('userspace_my_theaters')}
        >
          {trans('homepage_movie.no_favorite_theaters')}
        </a>
      );
    }

    return <DropdownLinks title={title} items={formatTheaters()} />;
  };

  return <div className="search-theater-favorite">{renderContent()}</div>;
};

const mapStateToProps = (state: State) => ({
  user: state.user
});

export default connect(mapStateToProps)(
  onlyIfHasSocial(FavoriteTheatersSelector)
);
