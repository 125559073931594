import React from 'react';

import trans from 'common/tools/translations/trans';

import SearchLocalizationForm, {
  SearchFormLocalizationProps
} from 'website/components/ui/SearchLocalizationForm';
import FavoriteTheatersSelector from 'website/components/user/FavoriteTheatersSelector';

const SearchTheater = (props: SearchFormLocalizationProps) => (
  <div>
    <SearchLocalizationForm {...props} autocompleteOnly={false} />
    <FavoriteTheatersSelector
      title={trans('homepage_movie.favorite_theaters')}
    />
  </div>
);

export default SearchTheater;
