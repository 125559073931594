export const GET_REVIEW_REQUEST = 'GET_REVIEW_REQUEST';
export const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS';
export const GET_REVIEW_FAILURE = 'GET_REVIEW_FAILURE';

export const DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAILURE = 'DELETE_REVIEW_FAILURE';

export const REVIEW_FORM_SELECT_ENTITY = 'REVIEW_FORM_SELECT_ENTITY';
export const REVIEW_FORM_SELECT_WHOLE_SERIES =
  'REVIEW_FORM_SELECT_WHOLE_SERIES';
export const REVIEW_FORM_SET_PRISTINE = 'REVIEW_FORM_SET_PRISTINE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const PASSIVE_LOGIN = 'PASSIVE_LOGIN';

export const ADD_ENTITIES = 'ADD_ENTITIES';

export const CHECK_SEENIT_REQUEST = 'CHECK_SEENIT_REQUEST';
export const CHECK_SEENIT_SUCCESS = 'CHECK_SEENIT_SUCCESS';
export const CHECK_SEENIT_FAILURE = 'CHECK_SEENIT_FAILURE';

export const CREATE_SEENIT_REQUEST = 'CREATE_SEENIT_REQUEST';
export const CREATE_SEENIT_SUCCESS = 'CREATE_SEENIT_SUCCESS';
export const CREATE_SEENIT_FAILURE = 'CREATE_SEENIT_FAILURE';

export const DELETE_SEENIT_REQUEST = 'DELETE_SEENIT_REQUEST';
export const DELETE_SEENIT_SUCCESS = 'DELETE_SEENIT_SUCCESS';
export const DELETE_SEENIT_FAILURE = 'DELETE_SEENIT_FAILURE';

export const GET_OPINION_REQUEST = 'GET_OPINION_REQUEST';
export const GET_OPINION_SUCCESS = 'GET_OPINION_SUCCESS';
export const GET_OPINION_FAILURE = 'GET_OPINION_FAILURE';
export const GET_MULTIPLE_OPINION_SUCCESS = 'GET_MULTIPLE_OPINION_SUCCESS';

export const DELETE_OPINION_REQUEST = 'DELETE_OPINION_REQUEST';
export const DELETE_OPINION_SUCCESS = 'DELETE_OPINION_SUCCESS';
export const DELETE_OPINION_FAILURE = 'DELETE_OPINION_FAILURE';

export const CREATE_OPINION_REQUEST = 'CREATE_OPINION_REQUEST';
export const CREATE_OPINION_SUCCESS = 'CREATE_OPINION_SUCCESS';
export const CREATE_OPINION_FAILURE = 'CREATE_OPINION_FAILURE';

export const UPDATE_OPINION_REQUEST = 'UPDATE_OPINION_REQUEST';
export const UPDATE_OPINION_SUCCESS = 'UPDATE_OPINION_SUCCESS';
export const UPDATE_OPINION_FAILURE = 'UPDATE_OPINION_FAILURE';

export const WRITE_REVIEW_REQUEST = 'WRITE_REVIEW_REQUEST';
export const WRITE_REVIEW_SUCCESS = 'WRITE_REVIEW_SUCCESS';
export const WRITE_REVIEW_FAILURE = 'WRITE_REVIEW_FAILURE';

export const GET_USER_THEATERS_REQUEST = 'GET_USER_THEATERS_REQUEST';
export const GET_USER_THEATERS_SUCCESS = 'GET_USER_THEATERS_SUCCESS';
export const GET_USER_THEATERS_FAILURE = 'GET_USER_THEATERS_FAILURE';

export const CREATE_FAVORITE_THEATER_REQUEST =
  'CREATE_FAVORITE_THEATER_REQUEST';
export const CREATE_FAVORITE_THEATER_SUCCESS =
  'CREATE_FAVORITE_THEATER_SUCCESS';
export const CREATE_FAVORITE_THEATER_FAILURE =
  'CREATE_FAVORITE_THEATER_FAILURE';

export const DELETE_FAVORITE_THEATER_REQUEST =
  'DELETE_FAVORITE_THEATER_REQUEST';
export const DELETE_FAVORITE_THEATER_SUCCESS =
  'DELETE_FAVORITE_THEATER_SUCCESS';
export const DELETE_FAVORITE_THEATER_FAILURE =
  'DELETE_FAVORITE_THEATER_FAILURE';

export const FACEBOOK_LIKE_ENTITY = 'FACEBOOK_LIKE_ENTITY';

export const GET_USER_FOLLOWEES_REQUEST = 'GET_USER_FOLLOWEES_REQUEST';
export const GET_USER_FOLLOWEES_SUCCESS = 'GET_USER_FOLLOWEES_SUCCESS';
export const GET_USER_FOLLOWEES_FAILURE = 'GET_USER_FOLLOWEES_FAILURE';

export const CREATE_FOLLOWEE_REQUEST = 'CREATE_FOLLOWEE_REQUEST';
export const CREATE_FOLLOWEE_SUCCESS = 'CREATE_FOLLOWEE_SUCCESS';
export const CREATE_FOLLOWEE_FAILURE = 'CREATE_FOLLOWEE_FAILURE';

export const DELETE_FOLLOWEE_REQUEST = 'DELETE_FOLLOWEE_REQUEST';
export const DELETE_FOLLOWEE_SUCCESS = 'DELETE_FOLLOWEE_SUCCESS';
export const DELETE_FOLLOWEE_FAILURE = 'DELETE_FOLLOWEE_FAILURE';

export const OPINION_ACTIONS_RETRIEVAL_REQUEST =
  'OPINION_ACTIONS_RETRIEVAL_REQUEST';
export const OPINION_ACTIONS_RETRIEVAL_SUCCESS =
  'OPINION_ACTIONS_RETRIEVAL_SUCCESS';
export const OPINION_ACTIONS_RETRIEVAL_FAILURE =
  'OPINION_ACTIONS_RETRIEVAL_FAILURE';

export const OPINION_ACTIONS_CREATE_REQUEST = 'OPINION_ACTIONS_CREATE_REQUEST';
export const OPINION_ACTIONS_CREATE_SUCCESS = 'OPINION_ACTIONS_CREATE_SUCCESS';
export const OPINION_ACTIONS_CREATE_FAILURE = 'OPINION_ACTIONS_CREATE_FAILURE';

export const OPINION_ACTIONS_DELETE_REQUEST = 'OPINION_ACTIONS_DELETE_REQUEST';
export const OPINION_ACTIONS_DELETE_SUCCESS = 'OPINION_ACTIONS_DELETE_SUCCESS';
export const OPINION_ACTIONS_DELETE_FAILURE = 'OPINION_ACTIONS_DELETE_FAILURE';

export const GET_FOLLOWEES_OPINION_REQUEST = 'GET_FOLLOWEES_OPINION_REQUEST';
export const GET_FOLLOWEES_OPINION_SUCCESS = 'GET_FOLLOWEES_OPINION_SUCCESS';
export const GET_FOLLOWEES_OPINION_FAILURE = 'GET_FOLLOWEES_OPINION_FAILURE';

export const SHOWTIME_FILTERS_INIT = 'SHOWTIME_FILTERS_INIT';
export const SHOWTIME_FILTERS_UPDATE = 'SHOWTIME_FILTERS_UPDATE';
export const SHOWTIME_PAGER_INIT = 'SHOWTIME_PAGER_INIT';
export const SHOWTIME_THEATER_INIT = 'SHOWTIME_THEATER_INIT';
export const SHOWTIME_THEATER_INIT_ALL = 'SHOWTIME_THEATER_INIT_ALL';
export const SHOWTIME_SEARCH_MODE_CHANGE = 'SHOWTIME_SEARCH_MODE_CHANGE';

export const FETCH_SHOWTIMES_REQUEST = 'FETCH_SHOWTIMES_REQUEST';
export const FETCH_SHOWTIMES_SUCCESS = 'FETCH_SHOWTIMES_SUCCESS';
export const FETCH_SHOWTIMES_FAILURE = 'FETCH_SHOWTIMES_FAILURE';

export const CLOSE_MOVIE_RATING_SUGGESTIONS = 'CLOSE_MOVIE_RATING_SUGGESTIONS';

export const GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_REQUEST =
  'GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_REQUEST';
export const GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_SUCCESS =
  'GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_SUCCESS';
export const GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_FAILURE =
  'GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_FAILURE';

export const CLOSE_AFFINITY_SUGGESTIONS = 'CLOSE_AFFINITY_SUGGESTIONS';
export const GET_AFFINITY_SUGGESTIONS_REQUEST =
  'GET_AFFINITY_SUGGESTIONS_REQUEST';
export const GET_AFFINITY_SUGGESTIONS_SUCCESS =
  'GET_AFFINITY_SUGGESTIONS_SUCCESS';
export const GET_AFFINITY_SUGGESTIONS_FAILURE =
  'GET_AFFINITY_SUGGESTIONS_FAILURE';

export const CREATE_SOCIAL_ACTION_REQUEST = 'CREATE_SOCIAL_ACTION_REQUEST';
export const CREATE_SOCIAL_ACTION_SUCCESS = 'CREATE_SOCIAL_ACTION_SUCCESS';
export const CREATE_SOCIAL_ACTION_FAILURE = 'CREATE_SOCIAL_ACTION_FAILURE';

export const DELETE_SOCIAL_ACTION_REQUEST = 'DELETE_SOCIAL_ACTION_REQUEST';
export const DELETE_SOCIAL_ACTION_SUCCESS = 'DELETE_SOCIAL_ACTION_SUCCESS';
export const DELETE_SOCIAL_ACTION_FAILURE = 'DELETE_SOCIAL_ACTION_FAILURE';
