export const SHOWTIMES_API_MODES = {
  NEAREST: 'nearest',
  IN: 'in',
  NETWORK: 'network',
  FAVORITES: 'favorites',
  REMAINING: 'remaining'
};

export const BOOKING = 'theater_has_booking';
export const LOYALTY_CARDS = 'loyalty_cards';
export const SHOWTIMES_COMFORT = 'showtimes.comfort';
export const SHOWTIMES_EXPERIENCE = 'showtimes.experience';
export const SHOWTIMES_PICTURE = 'showtimes.picture';
export const SHOWTIMES_PROJECTION = 'showtimes.projection';
export const SHOWTIMES_SOUND = 'showtimes.sound';
export const SHOWTIMES_VERSION = 'showtimes.version';

export const SHOWTIMES_EXPERIENCES_ICONS_CLASSES: Record<string, string> = {
  'Auditorium.Experience.4DEmotion': '4demotion',
  'Auditorium.Experience.4dx': '4dx',
  'Auditorium.Experience.DolbyAtmos': 'dolbyatmos',
  'Auditorium.Experience.DolbyCinema': 'dolbycinema',
  'Auditorium.Experience.EclairColor': 'eclaircolor',
  'Auditorium.Experience.GrandLarge': 'grandlarge',
  'Auditorium.Experience.Ice': 'ice',
  'Auditorium.Experience.Infinite': 'infinite',
  'Auditorium.Experience.LaserUltra': 'laserultra',
  'Auditorium.Experience.OnyxLed': 'onyxled',
  'Auditorium.Experience.ScreenX': 'screenx',
  'Format.Projection.3d': '3d',
  'Format.Projection.3d70mm': '3d',
  'Format.Projection.4k': '4k',
  'Format.Projection.4k3d': '4k3d',
  'Format.Projection.Imax': 'imax',
  'Format.Projection.Imax3d': 'imax3d',
  'Format.Projection.Imax70mm': 'imax',
  'Format.Projection.RealD3D': '3d',
  'Format.Sound.Atmos': 'dolbyatmos'
};
