import gql from 'graphql-tag';

import requestGraph from 'common/api/GraphApi/requestGraph';

import { UserActionPayload } from 'website/reducers/user';

import {
  retrieveUserTheatersMapper,
  addFavoriteTheaterMapper
} from './DotNet2GraphApi.helper';
import { exhaustPagination } from './GraphApi';

export const removeFavoriteTheater = (payload?: UserActionPayload | null) => {
  const QUERY = gql`
    mutation RemoveFavoriteTheater($theaterId: String!) {
      deleteUserTheater(input: { id_theater: $theaterId }) {
        id
      }
    }
  `;

  return requestGraph({
    query: QUERY,
    variables: {
      theaterId: window.btoa(`Theater:${payload?.theaterId?.toUpperCase()}`)
    }
  });
};

export const addFavoriteTheater = (theaterId: string) => {
  const QUERY = gql`
    mutation AddFavoriteTheater($theaterId: String!) {
      addUserTheater(input: { id_theater: $theaterId }) {
        internalId
        name
      }
    }
  `;

  return requestGraph({
    query: QUERY,
    variables: {
      theaterId: window.btoa(`Theater:${theaterId.toUpperCase()}`)
    }
  }).then(addFavoriteTheaterMapper);
};

export const retrieveUserTheaters = () => {
  const QUERY = gql`
    query GetUserTheaters($after: String) {
      me {
        user {
          social {
            theaters(after: $after) {
              totalCount
              pageInfo {
                hasNextPage
                endCursor
              }
              edges {
                node {
                  internalId
                  name
                }
              }
            }
          }
        }
      }
    }
  `;
  return exhaustPagination(QUERY, undefined, [
    'me',
    'user',
    'social',
    'theaters'
  ]).then(retrieveUserTheatersMapper);
};
