import { Maybe } from 'types/graphql-api.generated';

import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import * as events from 'common/tools/dom/events';
import hasTouch from 'common/tools/dom/getTouchBrowser';

type ElementTypes =
  | Window
  | Element
  | HTMLCollectionOf<Element>
  | HTMLElement
  | NodeList;

// private vars
let isNavigating = false;

let startX: Maybe<number> = null;
let startY: Maybe<number> = null;
let distanceX: Maybe<number> = null;
let distanceY: Maybe<number> = null;
let startTime: Maybe<number> = null;
let endTime = null;
let direction: Maybe<string> = null;

// event vars
let startEvent: Maybe<string> = null;
let endEvent: Maybe<string> = null;
let moveEvent: Maybe<string> = null;
const preventVerticalScroll = false;

// swipe handle config
const THRESHOLD = 50;
const RESTRAINT = 100;
const ALLOWEDTIME = 600;

export const SWIPE_LEFT = 'SWIPE_LEFT';
export const SWIPE_RIGHT = 'SWIPE_RIGHT';
export const SWIPE_TOP = 'SWIPE_TOP';
export const SWIPE_BOTTOM = 'SWIPE_BOTTOM';

// Global touch event re-writting
if (window.MSPointerEvent) {
  startEvent = 'MSPointerDown' || 'pointerdown';
  endEvent = 'MSPointerUp' || 'pointerup';
  moveEvent = 'MSPointerMove' || 'pointermove';
} else {
  startEvent = 'touchstart';
  endEvent = 'touchend';
  moveEvent = 'touchmove';
}

const start: EventListenerOrEventListenerObject = e => {
  // check to avoid multiple nav event in same time
  if (isNavigating) {
    return false;
  }

  isNavigating = true;
  // end check to avoid multiple nav event in same time

  if (e instanceof PointerEvent) {
    startX = e.clientX;
    startY = e.clientY;
  } else if (e instanceof TouchEvent) {
    const touchobj = e.changedTouches[0];
    startX = touchobj.pageX;
    startY = touchobj.pageY;
  }

  direction = 'none';
  distanceX = 0;
  distanceY = 0;
  startTime = new Date().getTime(); // record time when finger first makes contact with surface
};

const move: EventListenerOrEventListenerObject = e => {
  if (preventVerticalScroll) {
    e.preventDefault();
  }
};

const end = (element: ElementTypes, e: Event) => {
  if (e instanceof PointerEvent) {
    distanceX = e.clientX - (startX ?? 0);
    distanceY = e.clientY - (startY ?? 0);
  } else if (e instanceof TouchEvent) {
    const touchobj = e.changedTouches[0];
    distanceX = touchobj.pageX - (startX ?? 0); // get horizontal dist traveled by finger while in contact with surface
    distanceY = touchobj.pageY - (startY ?? 0); // get vertical dist traveled by finger while in contact with surface
  }

  endTime = new Date().getTime() - (startTime ?? 0); // get time elapsed

  if (endTime <= ALLOWEDTIME && distanceX !== null && distanceY !== null) {
    // HORIZONTAL SWIPE
    if (Math.abs(distanceX) >= THRESHOLD && Math.abs(distanceY) <= RESTRAINT) {
      // 2nd condition for horizontal swipe met
      if (distanceX < 0) {
        // go right (aka. move finger to the left)
        direction = SWIPE_RIGHT;
      } else {
        // go left (aka. move finger to the right)
        direction = SWIPE_LEFT;
      }
    }
    // VERTICAL SWIPE
    if (Math.abs(distanceY) >= THRESHOLD && Math.abs(distanceX) <= RESTRAINT) {
      if (distanceY < 0) {
        // go bottom (aka. move finger to the top)
        direction = SWIPE_BOTTOM;
      } else {
        // go top (aka. move finger to the bottom)
        direction = SWIPE_TOP;
      }
    }

    eventEmitter.emit(EventsTypes.SWIPE, [element, direction]);
  }

  // restore initial states after events :
  if (preventVerticalScroll && moveEvent) {
    events.on(
      element,
      moveEvent,
      () => true // restore move event
    );
  }

  isNavigating = false; // restore nav state to false since no more touch is done
};

export default (
  element?: ElementTypes,
  cb?: (element: Element | undefined, direction: Maybe<string>) => void
) => {
  if (!element || !hasTouch() || !cb) {
    return false;
  }

  if (startEvent) events.on(element, startEvent, start);
  if (moveEvent) events.on(element, moveEvent, move);
  if (endEvent) events.on(element, endEvent, end.bind(null, element));

  eventEmitter.on(EventsTypes.SWIPE, ([element, direction]) => {
    cb(element, direction);
  });
};
