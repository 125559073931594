import {
  TrackingContext,
  TrackingEventNames
} from 'common/constants/trackingEventsNames';
import eventEmitter from 'common/services/events/eventEmitter';

import { UserActionPayload } from 'website/reducers/user';

import * as DotNet2GraphApi from './DotNet2GraphApi';

/**
 * This file should contain / export all calls to secure-api.allocine.fr
 */

/**
 * Ask the api to load the favorite theaters of a user
 */
export const retrieveUserTheaters = () =>
  DotNet2GraphApi.retrieveUserTheaters();

/**
 * Ask the API to create a new favorite theater for the current user
 */
export const addFavoriteTheater = (
  theaterId: string,
  context: TrackingContext
) =>
  DotNet2GraphApi.addFavoriteTheater(theaterId).then(response => {
    eventEmitter.emit(TrackingEventNames.ADD_USER_THEATER_EVENT, {
      context,
      response
    });
    return response;
  });

/**
 * Ask the api to remove a theater from favorites
 */
export const removeFavoriteTheater = (
  payload: UserActionPayload | null | undefined,
  context: TrackingContext
) =>
  DotNet2GraphApi.removeFavoriteTheater(payload)
    .then(response => {
      eventEmitter.emit(TrackingEventNames.REMOVE_USER_THEATER_EVENT, {
        context,
        response
      });
      return response;
    })
    .then(() => payload);
