import React from 'react';
import { createRoot } from 'react-dom/client';

import readAttribute, {
  readBooleanAttribute,
  readNumberAttribute
} from 'common/tools/dom/readAttribute';

import AnchorPlus from 'website/components/anchor/AnchorPlus';

const loadAnchorPlus = async () => {
  const anchors = document.getElementsByClassName('js-anchor-plus');

  for (const anchor of anchors) {
    const root = createRoot(anchor);

    const tracking = readAttribute<Record<string, any>, undefined>(
      anchor,
      'data-jan'
    );

    root.render(
      <AnchorPlus
        ctaTextAfterRelease={readAttribute<string, undefined>(
          anchor,
          'data-cta-text-after-release'
        )}
        ctaTextBeforeRelease={readAttribute<string, undefined>(
          anchor,
          'data-cta-text-before-release'
        )}
        displayShowtimes={readBooleanAttribute(
          anchor,
          'data-display-showtimes'
        )}
        entityId={readNumberAttribute(anchor, 'data-entity-id')}
        entityRelease={readAttribute<string, undefined>(
          anchor,
          'data-entity-release'
        )}
        entityTitle={readAttribute<string, undefined>(
          anchor,
          'data-entity-title'
        )}
        entityType={readAttribute<string, undefined>(
          anchor,
          'data-entity-type'
        )}
        operationLink={readAttribute<string, undefined>(
          anchor,
          'data-operation-link'
        )}
        textAfterRelease={readAttribute<string, undefined>(
          anchor,
          'data-text-after-release'
        )}
        textBeforeRelease={readAttribute<string, undefined>(
          anchor,
          'data-text-before-release'
        )}
        theaterId={readAttribute<string, undefined>(anchor, 'data-theater-id')}
        tracking={typeof tracking === 'object' ? tracking : undefined}
      />
    );
  }
};

export default loadAnchorPlus;
