import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Autocomplete from 'common/components/ui/Autocomplete';
import debounce from 'common/tools/functions/debounce';
import trans from 'common/tools/translations/trans';

import { searchLocations } from 'website/api/Geocoding';
import { BRAND_HAS_THEATERS_GROUP } from 'website/configuration/constants';

class LocationAutocomplete extends Component {
  static propTypes = {
    inputProps: PropTypes.any.isRequired,
    onSearch: PropTypes.func.isRequired, // called
    onChange: PropTypes.func.isRequired,
    onInputValueChange: PropTypes.func.isRequired,
    getSearchUrl: PropTypes.func.isRequired,
    focusOnFirstRender: PropTypes.bool
  };

  static defaultProps = {
    focusOnFirstRender: false
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      items: []
    };

    this.handleTextChange = debounce(this.handleTextChange.bind(this));
  }

  getItemValue(item) {
    return item.node.name;
  }

  handleTextChange(event, value) {
    this.props.onInputValueChange(value);

    if (!value) {
      this.setState({ items: [] });
      return;
    }
    searchLocations(value).then(data => {
      data.values.cities.map(item => {
        item.node['type'] = 'city';
      });

      data.values.theaters.map(item => {
        item.node['type'] = 'theater';
      });

      const results = [...data.values.cities, ...data.values.theaters];
      results.push({
        node: {
          id: 'view_all',
          searchValue: value,
          name: trans('autocomplete.all-results', { term: value })
        }
      });
      this.setState({ items: results });
    });
  }

  handleSelect(value, item) {
    if (item && item.node.id === 'view_all') {
      return this.props.onSearch(item.node.searchValue);
    }
    return this.props.onChange(item);
  }

  handleEnter(value, item) {
    // user pressed the "enter" key
    if (item && item.node.id === 'view_all') {
      return this.props.onSearch(item.node.searchValue);
    }
    if (item) {
      return this.props.onChange(item);
    }
    return this.props.onSearch(value);
  }

  renderItem(item, props, highlighted) {
    if (item.node.id === 'view_all') {
      return (
        <div className="location-ac-suggestion" {...props}>
          <a
            className="location-ac-submit-link"
            href={this.props.getSearchUrl(item.node.searchValue)}
          >
            {item.node.name}
          </a>
        </div>
      );
    }
    const itemClass = classNames('location-ac-suggestion', {
      highlighted: highlighted
    });

    let name;

    if (item.node.type === 'city') {
      // localization case
      name =
        BRAND_HAS_THEATERS_GROUP || !item.node.zip
          ? item.node.name
          : `${item.node.name} - ${item.node.zip}`;
    } else {
      // theaterpage case
      name = `${item.node.name} - ${trans('theater')}`;
    }

    return (
      <div className={itemClass} {...props}>
        {name}
      </div>
    );
  }

  renderMenu(items, props) {
    const menuClass = classNames('location-ac-results', {
      hidden: !items.length
    });
    return (
      <div className={menuClass} {...props}>
        {items}
      </div>
    );
  }

  render() {
    return (
      <Autocomplete
        inputProps={this.props.inputProps}
        items={this.state.items}
        getItemValue={this.getItemValue.bind(this)}
        renderItem={this.renderItem.bind(this)}
        renderMenu={this.renderMenu.bind(this)}
        onChange={this.handleTextChange.bind(this)}
        onSelect={this.handleSelect.bind(this)}
        onEnter={this.handleEnter.bind(this)}
        focusOnFirstRender={this.props.focusOnFirstRender}
      />
    );
  }
}

export default LocationAutocomplete;
