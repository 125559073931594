import {
  retrieveUserTheaters,
  addFavoriteTheater,
  removeFavoriteTheater
} from 'common/api/SecureApi';
import { TrackingContext } from 'common/constants/trackingEventsNames';

import * as actionTypes from 'website/constants/ActionTypes';
import { State } from 'website/reducers';
import { UserActionPayload } from 'website/reducers/user';

/**
 * Async action: verify is a user is a fan
 */
export const getUserTheaters = () => ({
  types: [
    actionTypes.GET_USER_THEATERS_REQUEST,
    actionTypes.GET_USER_THEATERS_SUCCESS,
    actionTypes.GET_USER_THEATERS_FAILURE
  ],
  callAPI: () => retrieveUserTheaters(),
  payload: {},
  shouldCallAPI: (state: State) => state.user.theaters === null
});

/**
 * Async action: mark a theater as favorite
 */
export const addTheater = (theaterId: string, context: TrackingContext) => ({
  types: [
    actionTypes.CREATE_FAVORITE_THEATER_REQUEST,
    actionTypes.CREATE_FAVORITE_THEATER_SUCCESS,
    actionTypes.CREATE_FAVORITE_THEATER_FAILURE
  ],
  callAPI: () => addFavoriteTheater(theaterId, context),
  payload: { theaterId }
});

/**
 * Async action: remove the favorite theater flag for a user
 */
export const removeTheater = (
  payload: UserActionPayload | null | undefined,
  context: TrackingContext
) => ({
  types: [
    actionTypes.DELETE_FAVORITE_THEATER_REQUEST,
    actionTypes.DELETE_FAVORITE_THEATER_SUCCESS,
    actionTypes.DELETE_FAVORITE_THEATER_FAILURE
  ],
  callAPI: () => removeFavoriteTheater(payload, context),
  payload: { payload }
});
