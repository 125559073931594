import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Dropdown from 'common/components/Dropdown';

class DropdownLinks extends Component {
  render() {
    return (
      <Dropdown value={this.props.title}>
        {() => {
          return this.props.items.map(item => (
            <li className="item" key={item.href}>
              <a className="item-content" href={item.href}>
                {item.text}
              </a>
            </li>
          ));
        }}
      </Dropdown>
    );
  }
}

DropdownLinks.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired
};

export default DropdownLinks;
