import { ShowtimeModalParams } from 'common/components/ShowtimeModal';
import lazyModal from 'common/module/ui/lazy-modal';
import {
  ModalNames,
  ACCOUNT_ACTIVATION_EMAIL_SENT,
  ACCOUNT_CONFIRMED_ERROR,
  ACCOUNT_CONFIRMED_SUCCESS,
  ACCOUNT_ALREADY_CONFIRMED,
  ACCOUNT_CREATED_SUCCESS,
  ADD_TO_COLLECTION,
  NEWSLETTER,
  REVIEW,
  FOLLOWEES_RATING,
  EXPORT_PLAYER,
  SHARE_MODAL
} from 'common/module/ui/lazy-modal/constant-types';
import {
  ShareModalParams,
  AccountNeedsConfirmationModalParams,
  PromoteSignupModalParams
} from 'common/module/ui/lazy-modal/types';

import { Entity, SocialAction } from 'website/types';

type AddToCollectionModalParams = { entity?: Entity | SocialAction };

type ExportPlayerModalParams = {
  title: string;
  videoData: any;
};

type FolloweesRatingModalParams = {
  entity?: Entity | SocialAction;
};
type NewsletterModalParams = {
  campaignId: number;
  infoMsg: string;
  subscribedMsg: string;
  title: string;
  trackingSubject: string;
};

type ReviewModalParams = { entityId: string; openOn?: string; rating?: number };

export const openAccountActivationEmailSentModal = (
  error: boolean,
  email?: string
) => {
  lazyModal.open(ACCOUNT_ACTIVATION_EMAIL_SENT, { error, email });
};

export const openAccountAlreadyConfirmedModal = () => {
  lazyModal.open(ACCOUNT_ALREADY_CONFIRMED);
};

export const openAccountConfirmedErrorModal = () => {
  lazyModal.open(ACCOUNT_CONFIRMED_ERROR);
};

export const openAccountConfirmedSuccessModal = () => {
  lazyModal.open(ACCOUNT_CONFIRMED_SUCCESS);
};

export const openAccountCreatedSuccessModal = (nickname: string) => {
  lazyModal.open(ACCOUNT_CREATED_SUCCESS, { nickname });
};

export const openAccountNeedsConfirmationModal = (
  params?: AccountNeedsConfirmationModalParams
) => {
  lazyModal.open(ModalNames.ACCOUNT_NEEDS_CONFIRMATION, params);
};

export const openTOSValidationModal = () => {
  lazyModal.open(ModalNames.TOS_VALIDATION_MODAL);
};

export const openAddToCollectionModal = (
  params: AddToCollectionModalParams
) => {
  lazyModal.open(ADD_TO_COLLECTION, params);
};

export const openExportPlayerModal = (params: ExportPlayerModalParams) => {
  lazyModal.open(EXPORT_PLAYER, params);
};

export const openFolloweesRatingModal = (
  params: FolloweesRatingModalParams
) => {
  lazyModal.open(FOLLOWEES_RATING, params);
};

export const openNewsletterModal = (params: NewsletterModalParams) => {
  lazyModal.open(NEWSLETTER, params);
};

export const openPromoteAffinityModal = () => {
  lazyModal.open(ModalNames.PROMOTE_AFFINITY);
};

export const openPromoteSignupModal = (params: PromoteSignupModalParams) => {
  lazyModal.open(ModalNames.PROMOTE_SIGNUP, params);
};

export const openReviewModal = (params: ReviewModalParams) => {
  lazyModal.open(REVIEW, params);
};

export const openShareModal = (params: ShareModalParams) => {
  lazyModal.open(SHARE_MODAL, params);
};

export const openShowtimeModal = (params: ShowtimeModalParams) => {
  lazyModal.open(ModalNames.SHOWTIME_MODAL, params);
};
