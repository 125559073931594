/* global AC */
export const BRAND = AC.config.brand;
export const HOSTNAME = AC.config.hosts.hostname;
export const LOCALE = AC.config.locale;
export const ROUTE = AC.route;

export const SECURE_HOST = AC.config.hosts.secure;
export const SSO_HOST = AC.config.hosts.sso;
export const SECURE_VALIDATION_HOST = AC.config.hosts.secure;

export const ELASTIC_SEARCH_URL = AC.config.autocomplete;

export const PARTNER_ID = AC.config.secureApiPartnerKey;

export const USERSPACE_COOKIE_DOMAIN = AC.config.userspace.cookieDomain;
export const USERSPACE_PASSWORD_REGEXP = AC.config.userspace.passwordRegex;
export const USERSPACE_EMAIL_REGEXP = AC.config.userspace.emailRegexp;
export const USERSPACE_NICKNAME_REGEXP = AC.config.userspace.nicknameRegexp;

export const IS_USERSPACE = AC.config.isUserspace;

export const GOOGLE_API_KEY = AC.config.google.apiKey;
export const RELEASE_WEEK_DAY = AC.config.releaseWeekDay;
export const GOOGLE_APPLICATION_ID = AC.config.google.appId;
export const GOOGLE_PERMISSIONS = AC.config.google.permissions;
export const GOOGLE_TAG_MANAGER_ID = AC.config.google.tagManagerId;
export const GOOGLE_ANALYTICS_UA = AC.config.google.analyticsUACode;
export const GOOGLE_ANALYTICS_PARTNERS = AC.config.google.analyticsPartners;
export const GOOGLE_RECAPTCHA_SITEKEY = AC.config.google.reCaptchaSiteKey;

export const FACEBOOK_APPID = AC.config.facebook.appId;
export const FACEBOOK_PERMISSIONS = AC.config.facebook.permissions;
export const FACEBOOK_VERSION = AC.config.facebook.version;

export const ENV = AC.config.env;

// FEATURE FLAGS
export const BRAND_HAS_ACCES_LIBRE = AC.config.flags.brandHasAccesLibre;
export const BRAND_HAS_ADS_RESTRICTION = AC.config.flags.brandHasAdsRestriction;
export const BRAND_HAS_AFFINITY_SCORE = AC.config.flags.brandHasAffinityScore;
export const BRAND_HAS_CONTROL_OVER_RATINGS =
  AC.config.flags.brandHasControlOverRatings;
export const BRAND_HAS_DFP_DISPLAY_AUTO_REFRESH =
  AC.config.flags.brandHasDfpDisplayAutoRefresh;
export const BRAND_HAS_DM_PLAYER = AC.config.flags.brandHasDmPlayer;
export const BRAND_HAS_DM_VAST_FALLBACK =
  AC.config.flags.brandHasDmVastFallback;
export const BRAND_HAS_FB_CONNECT = AC.config.flags.brandHasFbConnect;
export const BRAND_HAS_JAN_TRACKING = AC.config.flags.brandHasJanTracking;
export const BRAND_HAS_FB_USER_FRIENDS = AC.config.flags.brandHasFbUserFriends;
export const BRAND_HAS_GA_ANONIMIZE_IP = AC.config.flags.brandHasGaAnonimizeIp;
export const BRAND_HAS_GOOGLE_ONE_TAP = AC.config.flags.brandHasGoogleOneTap;
export const BRAND_HAS_GO_IP_ADSERVER = AC.config.flags.brandHasGoIpAdserver;
export const BRAND_HAS_GROUP_CONSENTS = AC.config.flags.brandHasGroupConsents;
export const BRAND_HAS_HEADER_SEARCH_AUTOCOMPLETE =
  AC.config.flags.brandHasHeaderSearchAutocomplete;
export const BRAND_HAS_MQ_DFP_FIRST_LEVEL =
  AC.config.flags.brandHasMqDfpFirstLevel;
export const BRAND_HAS_NETFLIX_LOGO_OVERLAY =
  AC.config.flags.brandHasNetflixLogoOverlay;
export const BRAND_HAS_PAYWALL = AC.config.flags.brandHasPaywall;
export const BRAND_HAS_REWIND_OPE = AC.config.flags.brandHasRewindOpe;
export const BRAND_HAS_SAME_LOCAL_AND_DUBBED_SHOWTIMES =
  AC.config.flags.brandHasSameLocalAndDubbedShowtimes;
export const BRAND_HAS_STICKY_ADS = AC.config.flags.brandHasStickyAds;
export const BRAND_HAS_TOS_VALIDATION_WITH_GOOGLE =
  AC.config.flags.brandHasTOSValidationWithGoogle;
export const BRAND_HAS_TVTY = AC.config.flags.brandHasTvty;
export const BRAND_HAS_WHATSAPP_SHARE = AC.config.flags.brandHasWhatsappShare;

export const IMAGES_SHARD_BASENAME = AC.config.imageSharding.baseUrl;
export const IMAGES_SHARD_COUNT = +AC.config.imageSharding.serverCount; // convert string to number
export const IMAGES_SHARD_DOMAIN = AC.config.imageSharding.domain;

export const ASSETS_VERSION = AC.config.asset_version;
export const ASSETS_HOST = AC.config.hosts.assets;
export const GRAPH_HOST = AC.config.hosts.graph;
export const GRAPH_COUNTRY_NAME = AC.config.graphCountryName;
export const IMAGE_EMPTY_PHOTO_PORTRAIT = AC.config.images.emptyPhotoPor;
export const IMAGE_EMPTY_PHOTO_LANDSCAPE = AC.config.images.emptyPhotoLan;
export const IMAGE_EMPTY_AVATAR = AC.config.images.emptyAvatar;

export const CSS_FONT_URL = AC.config.assetsUrls.css.font;

export const IMG_BRONSON_URL = AC.config.assetsUrls.images.bronson;
export const IMG_CINE_RELAX = AC.config.assetsUrls.images.cineRelax;
export const IMG_ETOILE_URL = AC.config.assetsUrls.images.etoile;
export const IMG_GHOSTBUSTER_URL = AC.config.assetsUrls.images.ghostBuster;
export const IMG_LOGO_DARK = AC.config.assetsUrls.images.logoDark;
export const IMG_LOGO_URL = AC.config.assetsUrls.images.logo;
export const IMG_MACARON_URL = AC.config.assetsUrls.images.macaron;
export const IMG_NOT_FOUND_URL = AC.config.assetsUrls.images.notFound;
export const IMG_ONBOARDING = AC.config.assetsUrls.images.onboarding;
export const IMG_REWIND_2024 = AC.config.assetsUrls.images.rewind2024;
export const IMG_SERIOUSLY_URL = AC.config.assetsUrls.images.seriously;
export const IMG_SUGGESTIONS = AC.config.assetsUrls.images.suggestions;
export const IMG_WALLE_URL = AC.config.assetsUrls.images.walle;

export const TWITTER_HANDLE = AC.config.twitter.handle;

export const USE_NEW_SSO = AC.config.flags.useNewSso;

export const TECHNOS_PREMIUM = AC.config.technosPremium;

export const USERSPACE_ROUTES = {
  myMovies: {
    path: 'userspace_my_movies',
    icon: 'icon-mac-my-movies',
    label: 'userspace.menu.my-movies.title'
  },
  mySeries: {
    path: 'userspace_my_series',
    icon: 'icon-mac-my-series',
    label: 'userspace.menu.my-series.title'
  },
  myCollections: {
    path: 'userspace_my_collections',
    icon: 'icon-mac-my-collections',
    label: 'userspace.menu.my-collections.title'
  },
  myReviews: {
    path: 'userspace_my_reviews',
    icon: 'icon-mac-my-reviews',
    label: 'userspace.menu.my-reviews.title'
  },
  myCommunity: {
    path: 'userspace_my_community',
    icon: 'icon-mac-my-community',
    label: 'userspace.menu.my-community.title'
  },
  myTheaters: {
    path: 'userspace_my_theaters',
    icon: 'icon-mac-my-theaters',
    label: 'userspace.menu.my-theaters.title'
  },
  settings: {
    path: 'userspace_settings',
    icon: 'icon-mac-settings',
    label: 'userspace.menu.settings.title'
  }
};
