import classnames from 'classnames';
import React, { ReactNode } from 'react';

import { Showtime } from 'types/graphql-api.generated';

import { SHOWTIMES_EXPERIENCES_ICONS_CLASSES } from 'common/constants/Showtimes';

type Experiences = Showtime['tags'];

export type ExperienceIconsProps = {
  extraClassName?: string;
  experiences?: Experiences;
};

type ShowtimeExperiencesProps = ExperienceIconsProps & {
  className?: string;
};

const ExperienceIcons = ({
  experiences,
  extraClassName
}: ExperienceIconsProps) => (
  <>
    {[
      ...new Set(
        // Some experiences share the same icon, we use a set to avoid duplication
        (experiences ?? []).map(exp => SHOWTIMES_EXPERIENCES_ICONS_CLASSES[exp])
      )
    ].reduce<ReactNode[]>((acc, icon) => {
      if (icon) {
        acc.push(
          <span
            className={classnames(
              extraClassName,
              'square',
              'icon',
              'colorful-icon',
              `icon-${icon}`
            )}
            key={icon}
          />
        );
      }
      return acc;
    }, [])}
  </>
);

const ShowtimeExperiences = ({
  className,
  ...props
}: ShowtimeExperiencesProps) =>
  className ? (
    <div className={className}>
      <ExperienceIcons {...props} />
    </div>
  ) : (
    <ExperienceIcons {...props} />
  );
export default ShowtimeExperiences;
