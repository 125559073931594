import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { addClass, hasClass, replaceClass } from 'common/tools/dom/classes';
import onUserAction from 'common/tools/ui/defer-on-user-action';

const timeBeforePlay = 200;
let timerFadeIn;
let timerFadeOut;
let isVideoPlaying;

function addFadeIn(video) {
  if (hasClass(video, 'fade-out')) {
    replaceClass(video, 'fade-out', 'fade-in');
  } else {
    addClass(video, 'fade-in');
  }
}

function addFadeout(video) {
  if (hasClass(video, 'fade-in')) {
    replaceClass(video, 'fade-in', 'fade-out');
  }
}

function clearTimer() {
  if (timerFadeIn) {
    clearTimeout(timerFadeIn);
    timerFadeIn = null;
  }

  if (timerFadeOut) {
    clearTimeout(timerFadeOut);
    timerFadeOut = null;
  }
}

function stopPlaying(video) {
  clearTimer();

  if (!isVideoPlaying) {
    return;
  }
  addFadeout(video);
  eventEmitter.emit(EventsTypes.VIDEO_IS_STOPPED);
  isVideoPlaying = false;
}

function startPlaying(video) {
  clearTimer();
  eventEmitter.emit(EventsTypes.VIDEO_IS_PLAYING);
  timerFadeIn = setTimeout(() => {
    addFadeIn(video);
    video.currentTime = 0;
    video.play();
    isVideoPlaying = true;
  }, timeBeforePlay);

  // Start the moment when fade out will occur regardless of any other action
  const timeToFadeOut = 1000 * (video.duration - 1) + timeBeforePlay;
  timerFadeOut = setTimeout(() => {
    stopPlaying(video);
  }, timeToFadeOut);
}

// Default function that allows to start or not the video
export default function initCarouselVideo() {
  const video_slide = document.getElementById('video-slide');
  const video = document.getElementById('carousel-video-player');

  if (!video) {
    return false;
  }

  // Start to play the video
  onUserAction(startPlaying.bind(null, video));

  // while slides are changing, if the video slide is the current slide, i want the video to play.
  // if not, i want it to stop
  eventEmitter.on(EventsTypes.SLIDE_CHANGED, () => {
    if (hasClass(video_slide, 'current')) {
      startPlaying(video);
    } else {
      stopPlaying(video);
    }
  });
}
