import React from 'react';
import { createRoot } from 'react-dom/client';

import readAttribute from 'common/tools/dom/readAttribute';

import SearchTheater from 'website/components/push/SearchTheater';
import { RouteProps } from 'website/components/ui/SearchLocalizationForm';
import allocineContainer from 'website/containers/allocineContainer';

export default function searchTheaterPush() {
  const positions = document.getElementsByClassName('js-search-theater-push');
  if (positions.length) {
    const ConnectedSearchTheater = allocineContainer(SearchTheater);
    for (const position of positions) {
      const route = readAttribute<RouteProps>(position, 'data-route');
      const placeholder = readAttribute<string>(position, 'data-placeholder');
      const root = createRoot(position);
      root.render(
        <ConnectedSearchTheater
          route={typeof route === 'string' ? undefined : route}
          placeholder={placeholder}
        />
      );
    }
  }
}
