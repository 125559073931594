import { hasClass, removeClass, toggleClass } from 'common/tools/dom/classes';
import * as events from 'common/tools/dom/events';

// private vars :
const dropdownColl = document.getElementsByClassName('js-dropdown');

function toggleList() {
  toggleClass(this, 'is-open');
}

function hideList() {
  if (hasClass(this, 'is-open')) {
    removeClass(this, 'is-open');
  }
}

function init() {
  events.on(dropdownColl, 'click', toggleList);
  events.on(dropdownColl, 'mouseleave', hideList);
}

export default init;
