import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { addClass, removeClass, hasClass } from 'common/tools/dom/classes';
import * as events from 'common/tools/dom/events';
import hasTouch from 'common/tools/dom/getTouchBrowser';
import readAttribute from 'common/tools/dom/readAttribute';
import swipeOn from 'common/tools/dom/swipe';
import onUserAction from 'common/tools/ui/defer-on-user-action';
import { print } from 'common/tracking/googleanalytics';

import { KEY_LEFT, KEY_RIGHT } from 'website/constants/Keyboard';

class Carousel {
  constructor(element) {
    this.root = element;
    this.btnLeft = this.root.getElementsByClassName('carousel-prev')[0];
    this.btnRight = this.root.getElementsByClassName('carousel-next')[0];
    this.carouselRow = this.root.getElementsByClassName('carousel-row')[0];
    this.items = this.root.getElementsByClassName('carousel-item');
    this.currentItem = this.root.getElementsByClassName('current')[0];
    this.itemsLenght = this.items.length;
    this.carouselHidden = hasClass(this.root, 'carousel-hidden');

    this.slideIndexGaPrinted = [];

    // fallback for "pinces" who made carousel with one item (should never happend, twig control)
    // without return false for "petit guitounette des champs" happiness (or the man become mad)
    if (this.items.length <= 1) {
      addClass(this.root, 'carousel-no-item');
    } else {
      // random slide selection if none has been set
      if (!this.currentItem) {
        this.setFirstItem();
      }

      this.index = Array.prototype.indexOf.call(this.items, this.currentItem);
      this.timer = null;
      this.isRunning = false;

      this.auto = !!(
        !hasTouch() &&
        this.root.getAttribute('data-auto') &&
        this.root.getAttribute('data-auto') === 'true'
      ); // eslint-disable-line
      this.timerAuto = null;
      this.disableAuto = true;

      // nav action
      events.on(this.btnLeft, 'click', this.handleClick.bind(this, -1));
      events.on(this.btnRight, 'click', this.handleClick.bind(this, 1));
      events.on(window, 'keydown', this.handleKeydown);

      swipeOn(this.root, this.handleSwipe);

      // counter increment
      this.changeCounter(this.currentItem);

      // print tracking (click is still handled in the twig with data-trigger)
      this.handleGaPrint(this.currentItem);

      const videoSlide = document.getElementById('video-slide');

      // auto roller
      if (this.auto) {
        this.disableAuto = false;

        if (videoSlide) {
          eventEmitter.on(EventsTypes.VIDEO_IS_PLAYING, () => {
            this.clearTimerAuto();
          });

          let timerVideoSlide;
          eventEmitter.on(EventsTypes.VIDEO_IS_STOPPED, () => {
            if (this.disableAuto) {
              return false;
            }

            clearTimeout(timerVideoSlide);
            timerVideoSlide = setTimeout(() => {
              this.changeSlide(1);
              this.triggerAuto();
            }, 2000);
          });
        } else {
          this.triggerAuto();
        }

        events.on(this.root, 'mouseenter', this.handleHover);
        events.on(this.root, 'mouseleave', this.handleHover);
      }
    }

    // show carousel
    if (this.carouselHidden) {
      removeClass(this.root, 'carousel-hidden');
    }
  }

  handleSwipe = (element, direction) => {
    this.disableAutoRoller();

    if (element === this.root) {
      switch (direction) {
        case 'SWIPE_LEFT':
          this.changeSlide(-1);
          break;
        case 'SWIPE_RIGHT':
          this.changeSlide(1);
          break;
        default:
          return false;
      }
    }
  };

  handleClick = direction => {
    this.disableAutoRoller();

    this.changeSlide(direction);
  };

  handleKeydown = event => {
    this.disableAutoRoller();

    switch (event.keyCode) {
      case KEY_RIGHT:
        this.changeSlide(1);
        break;
      case KEY_LEFT:
        this.changeSlide(-1);
        break;
      default:
        return false;
    }
  };

  handleHover = event => {
    if (this.disableAuto) {
      return false;
    }

    switch (event.type) {
      case 'mouseenter':
        this.clearTimerAuto();
        break;
      case 'mouseleave':
        this.setTimerAuto();
        break;
      default:
        return false;
    }
  };

  changeSlide(direction) {
    if (this.isRunning) {
      return false;
    }

    this.isRunning = true;

    const directionClass = direction === 1 ? 'go-right' : 'go-left';
    const nextItemClass = direction === 1 ? 'right-item' : 'left-item';
    let nextItem = null;

    if (this.index + direction === this.itemsLenght) {
      // we are on the last item we want fist item index
      this.index = 0;
    } else if (this.index + direction === -1) {
      // we are on the first item, we want last item index
      this.index = this.itemsLenght - 1;
    } else {
      // we're not at the beginning or the end, get the next slide index
      this.index = this.index + direction;
    }

    // get the next slide to show :
    nextItem = this.items[this.index];

    // position the next slide :
    addClass(nextItem, nextItemClass);

    // trigger eventEmiter to show next slide img if needed
    eventEmitter.emit(EventsTypes.LAZY_REVALIDATE);

    // trigger the animation :
    this.move(directionClass, nextItemClass, nextItem);
  }

  move(directionClassName, nextItemClassName, nextCurrent) {
    this.clearTimer();

    // add animation class to the container :
    addClass(this.carouselRow, 'animate');

    // add animation position class to the container (will make it roll) :
    addClass(this.carouselRow, directionClassName);

    // Once the animation is finished reset the container, and the slide classe
    // then enable the next click action.
    this.timer = setTimeout(
      function timer() {
        removeClass(this.currentItem, 'current');

        addClass(nextCurrent, 'current');
        removeClass(nextCurrent, nextItemClassName);

        removeClass(this.carouselRow, 'animate');
        removeClass(this.carouselRow, directionClassName);

        this.currentItem = nextCurrent;

        eventEmitter.emit(EventsTypes.SLIDE_CHANGED);

        this.isRunning = false;
      }.bind(this),
      650
    );

    // past nextCurrent here because it will be the currentItem in 650ms (avoid invoking changeCounter in the setTimeout)
    this.changeCounter(nextCurrent);

    // print trakcing handling
    this.handleGaPrint(nextCurrent);
  }

  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  setTimerAuto(time = 5000) {
    if (this.disableAuto) {
      return false;
    }

    this.timerAuto = setInterval(
      function timer() {
        this.changeSlide(1);
      }.bind(this),
      time
    );
  }

  clearTimerAuto() {
    if (this.timerAuto) {
      clearInterval(this.timerAuto);
      this.timerAuto = null;
    }
  }

  disableAutoRoller() {
    this.clearTimerAuto();
    this.disableAuto = true;
    this.auto = false;
  }

  setFirstItem() {
    const random = Math.floor(Math.random() * (this.itemsLenght - 1 + 1));
    this.currentItem = this.items[random];
    addClass(this.currentItem, 'current');
  }

  changeCounter(item) {
    const carouselDot = document.getElementById(
      `counter-item-${item.getAttribute('data-index')}`
    );

    document.querySelectorAll('.carousel-dot').forEach(item => {
      removeClass(item, 'carousel-dot-current');
    });

    addClass(carouselDot, 'carousel-dot-current');
  }

  triggerAuto() {
    // This is done to trigger the carousel auto on user action on the window (means there is actually a user)
    // Unfortunatly speedindex & pagespeed will also be dependant of random slide action if no emergence : fix for that is tested on main-carousel.html.twig by rendering random first slide just after rendering the carousel

    const handleTriggerAuto = () => {
      this.disableAuto = false;
      this.setTimerAuto();
    };

    onUserAction(handleTriggerAuto);
  }

  handleGaPrint(slide) {
    const slideIndex = readAttribute(slide, 'data-index');
    const slideDataGa = readAttribute(slide, 'data-ga');
    const slideIsSponso = readAttribute(slide, 'data-emergence');

    if (
      slideIsSponso &&
      slideDataGa &&
      this.slideIndexGaPrinted.indexOf(slideIndex) === -1
    ) {
      print(slideDataGa);
      this.slideIndexGaPrinted.push(slideIndex);
    }
  }
}

export default function () {
  const carouselElements = document.getElementsByClassName('js-carousel');
  if (!carouselElements.length) {
    return null;
  }

  for (const carousel of carouselElements) {
    return new Carousel(carousel);
  }
}
